<footer>
  <a [routerLink]="['/']" class="logo-wrapper">
    <img
      class="logo"
      src="/assets/images/compensate/compensate-logo-alt.svg"
      alt="Compensate logo"
    />
  </a>

  <section class="about">
    <app-social-media-links
      color="white"
      [socialLinks]="environment.navigation.social"
    ></app-social-media-links>
    <p>
      {{ environment.footer.body }}
    </p>
  </section>

  <div class="links">
    <app-dynamic-link
      *ngFor="let link of environment.footer.links"
      [link]="link"
    >
      {{ link.text }}
    </app-dynamic-link>
  </div>

  <div class="legal">
    <app-dynamic-link [link]="environment.footer.privacyLink">
      {{ environment.footer.privacyLink.text }}
    </app-dynamic-link>

    <app-dynamic-link [link]="environment.footer.termsLink">
      {{ environment.footer.termsLink.text }}
    </app-dynamic-link>

    <app-dynamic-link [link]="environment.footer.impressumLink">
      {{ environment.footer.impressumLink.text }}
    </app-dynamic-link>

    <div class="copyright">
      <p>
        {{ environment.footer.copyright }}
      </p>
    </div>
  </div>
</footer>
